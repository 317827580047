import React from "react";

import frontImage1 from "../../assets/images/frontpage/children.jpg";
import frontImage2 from "../../assets/images/frontpage/joe-books-kids.jpg";
import frontImage3 from "../../assets/images/frontpage/lepers-food.jpg";

const Services = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center py-20 md:h-screen items-center gap-x-16 gap-y-8">
      <article
        class="relative w-full h-64 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl  transition duration-300 ease-in-out"
        style={{
          backgroundImage: `url(${frontImage1})`,
          backgroundSize: "cover",
          backgroundColor: "black",
          opacity: 50,

          width: 300,
          height: 500,
        }}
      >
        <div class="absolute inset-0 bg-gradient-to-t from-green-900  bg-opacity-0 group-hover:opacity-60 transition duration-300 ease-in-out"></div>
        <div class="relative w-full h-full px-4 sm:px-6 lg:px-4 flex justify-center items-end pb-10">
          <h3 class="text-center">
            <a class="text-white text-2xl font-bold text-center" href="#">
              <span class="absolute inset-0"></span>
              Ministry among slum children
            </a>
          </h3>
        </div>
      </article>

      <article
        class="relative w-full h-64 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl  transition duration-300 ease-in-out"
        style={{
          backgroundImage: `url(${frontImage2})`,
          backgroundSize: "cover",
          backgroundColor: "black",
          opacity: 50,

          width: 300,
          height: 500,
        }}
      >
        <div class="absolute inset-0 bg-gradient-to-t from-yellow-900  bg-opacity-0 group-hover:opacity-60 transition duration-300 ease-in-out"></div>
        <div class="relative w-full h-full px-4 sm:px-6 lg:px-4 flex justify-center items-end pb-10">
          <h3 class="text-center">
            <a class="text-white text-2xl font-bold text-center" href="#">
              <span class="absolute inset-0"></span>
              Ministry among Street Kids
            </a>
          </h3>
        </div>
      </article>

      <article
        class="relative w-full h-64 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl  transition duration-300 ease-in-out"
        style={{
          backgroundImage: `url(${frontImage3})`,
          backgroundSize: "cover",
          backgroundColor: "black",
          opacity: 50,

          width: 300,
          height: 500,
        }}
      >
        <div class="absolute inset-0 bg-gradient-to-t from-blue-900  bg-opacity-0 group-hover:opacity-60 transition duration-300 ease-in-out"></div>
        <div class="relative w-full h-full px-4 sm:px-6 lg:px-4 flex justify-center items-end pb-10">
          <h3 class="text-center">
            <a class="text-white text-2xl font-bold text-center" href="#">
              <span class="absolute inset-0"></span>
              Ministry among Lepers
            </a>
          </h3>
        </div>
      </article>
    </div>
  );
};

export default Services;
