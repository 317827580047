import React from "react";
import blogImage1 from "../../assets/images/frontpage/joe-food.jpg";
import BlockTitle from "../block-title";

const Vision = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center  items-center px-20 py-20">
      <img
        src={blogImage1}
        alt="Vision"
        width="300px"
        className="rounded-2xl mx-20"
      />

      <div className="pt-10 pb-10 leading-tight w-96">
        <BlockTitle
          tagLine="Vision"
          title="Our vision is to see People changed by the love of Christ."
        />
        <p>
          Though many people in have never heard the name of Jesus, hearts are
          open to the Good News like never before! Keep scrolling to see how you
          can join us in this journey of spreading hope.
        </p>
        <h1 className="pt-10">Joseph Nigro</h1>
        <h1>Founder of Straight Path Ministries</h1>
      </div>
    </div>
  );
};

export default Vision;
