import React from "react";
import Layout from "../components/Layout";
import HeroFullScreen from "../components/Frontpage/HeroFullScreen";
import BibleVerse1 from "../components/Frontpage/bible-verse-1";

import Services from "../components/Frontpage/services";
import Vision from "../components/Frontpage/vision";
import herobanner from "../assets/images/banners/joseph-kids.jpg";

const HomeOne = () => {
  return (
    <Layout pageTitle="Straight Path Ministries || Missions ">
      <HeroFullScreen
        bgImage={herobanner}
        title="Global Mission: Reaching to the unreached"
      />
      <Vision />
      <BibleVerse1 />
      <Services />
    </Layout>
  );
};

export default HomeOne;
