import * as React from "react";

const HeroFullScreen = ({ bgImage, title }) => {
  return (
    <section>
      <div
        class="w-full bg-center bg-cover h-screen"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundColor: "black",
        }}
      >
        <div class="flex items-center justify-center w-full h-full px-10">
          <div class="text-center bg-gray-900 bg-opacity-80 p-6 rounded-xl">
            <h1 class="font-body text-4xl font-semibold text-white lg:text-3xl">
              {title}
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroFullScreen;
