import React from "react";
import frontImage3 from "../../assets/images/frontpage/large-2.jpg";

const BibleVerse1 = () => {
  return (
    <article className="w-full bg-gradient-to-r from-green-400 to-blue-500">
      <div className="mx-10 sm:mx-10 md:mx-10 lg:mx-80">
        <h3 class="text-center py-20 items-center justify-center">
          <a class="text-white text-3xl font-bold text-center justify-center ">
            Jesus said, “Let the little children come to me, and do not hinder
            them, for the kingdom of heaven belongs to such as these.”
            <h1 className="text-4xl pt-6">Matthew 19:14</h1>
          </a>
        </h3>
      </div>
    </article>
  );
};

export default BibleVerse1;
